import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './Header';
import Footer from './Footer';

import logo from './images/zeus-logo-white.svg';

// clouds
import cloud1 from './images/cloud1.png';
import cloud2 from './images/cloud2.png';
import cloud3 from './images/cloud3.png';
import cloud4 from './images/cloud4.png';
import cloud5 from './images/cloud5.png';

import nodeManagement from './images/white/node-management.svg';
import cart from './images/white/cart.svg';
import accounting from './images/white/accounting.svg';
import relay from './images/white/relay.svg';
import help from './images/white/help.svg';
import bell from './images/white/bell.svg';
import phone from './images/white/phone.svg';
import enterprise from './images/white/enterprise.svg';

import LoadingIndicator from './components/LoadingIndicator';

import { useOnScreen } from './Utils';

export default function White() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);

        axios
            .post(
                `${
                    process.env.NODE_ENV === 'development' ? '' : '/api'
                }/white/submit`,
                {
                    name,
                    email,
                    message
                }
            )
            .then((response: any) => {
                if (response.data.success) {
                    setSuccessMessage(
                        "Succesfully sent! We'll get back to you as soon as possible. Thank you."
                    );
                    setIsSubmitting(false);
                } else {
                    setErrorMessage(
                        'Error submitting your message. Please try again later.'
                    );
                    setIsSubmitting(false);
                }
            })
            .catch(function () {
                setErrorMessage(
                    'Error submitting your message. Please try again later.'
                );
                setIsSubmitting(false);
            });
    };

    const ref1 = useRef<HTMLDivElement>(null);
    const isVisible1 = useOnScreen(ref1);

    const ref2 = useRef<HTMLDivElement>(null);
    const isVisible2 = useOnScreen(ref2);

    const ref3 = useRef<HTMLDivElement>(null);
    const isVisible3 = useOnScreen(ref3);

    const ref4 = useRef<HTMLDivElement>(null);
    const isVisible4 = useOnScreen(ref4);

    const ref5 = useRef<HTMLDivElement>(null);
    const isVisible5 = useOnScreen(ref5);

    const ref6 = useRef<HTMLDivElement>(null);
    const isVisible6 = useOnScreen(ref6);

    const ref7 = useRef<HTMLDivElement>(null);
    const isVisible7 = useOnScreen(ref7);

    const ref8 = useRef<HTMLDivElement>(null);
    const isVisible8 = useOnScreen(ref8);

    return (
        <div className="App">
            <Header />
            <div className="White">
                <header className="white-banner">
                    <div className="overlay"></div>
                    <div className="white-logo">
                        <img src={logo} height="175vh" alt="ZEUS" />
                        <h2>white</h2>
                    </div>
                    <div className="clouds">
                        <img
                            src={cloud1}
                            style={{ '--i': 1 } as React.CSSProperties}
                        />
                        <img
                            src={cloud2}
                            style={{ '--i': 2 } as React.CSSProperties}
                        />
                        <img
                            src={cloud3}
                            style={{ '--i': 3 } as React.CSSProperties}
                        />
                        <img
                            src={cloud4}
                            style={{ '--i': 4 } as React.CSSProperties}
                        />
                        <img
                            src={cloud5}
                            style={{ '--i': 5 } as React.CSSProperties}
                        />
                        <img
                            src={cloud1}
                            style={{ '--i': 10 } as React.CSSProperties}
                        />
                        <img
                            src={cloud2}
                            style={{ '--i': 9 } as React.CSSProperties}
                        />
                        <img
                            src={cloud3}
                            style={{ '--i': 8 } as React.CSSProperties}
                        />
                        <img
                            src={cloud4}
                            style={{ '--i': 7 } as React.CSSProperties}
                        />
                        <img
                            src={cloud5}
                            style={{ '--i': 6 } as React.CSSProperties}
                        />
                    </div>
                </header>
                <div className="White-content">
                    <div className="White-section1">
                        <div className="white-text">
                            <h3>
                                Integrating Bitcoin into your business is easier
                                with a helping hand
                            </h3>
                            <p>
                                We're excited to present ZEUS White: our white
                                glove node management, point of sale, and
                                integrated enterprise solutions for businesses.
                                These services are built around tooling we've
                                engineered in-house for managing our world class
                                LSP, as well as solutions we've crafted to
                                optimize operations for exchanges and merchants
                                over the last four years.{' '}
                            </p>
                            <p>
                                Our services can be configured numerous way to
                                meet your specific needs, whether you're a
                                merchant, large enterprise, or anywhere in
                                between.
                            </p>
                        </div>
                        <div className="whiteServices">
                            <Grid container spacing={1} columns={12}>
                                <Grid item md={3}>
                                    <div
                                        ref={ref1}
                                        className={`screenshot animated animatedFadeInUp ${
                                            isVisible1 ? 'fadeInUp' : ''
                                        }`}
                                    >
                                        <img
                                            src={nodeManagement}
                                            alt="ZEUS White Node management"
                                        />
                                        <h3>
                                            <p className="highlighted-text">
                                                Node management
                                            </p>
                                        </h3>
                                        <p className="subtitle">
                                            Save yourself hours of time managing
                                            your node by leaving it to the pros.
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        ref={ref2}
                                        className={`screenshot animated2 animatedFadeInUp ${
                                            isVisible2 ? 'fadeInUp' : ''
                                        }`}
                                    >
                                        <img
                                            src={cart}
                                            alt="ZEUS White Point of sale solutions"
                                        />
                                        <h3>
                                            <p className="highlighted-text">
                                                Point of sale solutions
                                            </p>
                                        </h3>
                                        <p className="subtitle">
                                            Accept Bitcoin payments in person
                                            and online.
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        ref={ref3}
                                        className={`screenshot animated3 animatedFadeInUp ${
                                            isVisible3 ? 'fadeInUp' : ''
                                        }`}
                                    >
                                        <img
                                            src={accounting}
                                            alt="ZEUS White Accounting and reconciliation"
                                        />
                                        <h3>
                                            <p className="highlighted-text">
                                                Accounting and reconciliation
                                            </p>
                                        </h3>
                                        <p className="subtitle">
                                            Keep track of each and every
                                            satoshi.
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        ref={ref4}
                                        className={`screenshot animated4 animatedFadeInUp ${
                                            isVisible4 ? 'fadeInUp' : ''
                                        }`}
                                    >
                                        <img
                                            src={relay}
                                            alt="ZEUS White Regulatory compliance tooling"
                                        />
                                        <h3>
                                            <p className="highlighted-text">
                                                Regulatory compliance
                                            </p>
                                        </h3>
                                        <p className="subtitle">
                                            Rest easy knowing you have
                                            up-to-date controls in place for
                                            your jurisdiction.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} columns={12}>
                                <Grid item md={3}>
                                    <div
                                        ref={ref5}
                                        className={`screenshot animated animatedFadeInUp ${
                                            isVisible5 ? 'fadeInUp' : ''
                                        }`}
                                    >
                                        <img
                                            src={help}
                                            alt="ZEUS White Backups + Disaster fund recovery"
                                        />
                                        <h3>
                                            <p className="highlighted-text">
                                                Backups + Disaster fund recovery
                                            </p>
                                        </h3>
                                        <p className="subtitle">
                                            We've seen it all. Recovering funds
                                            in highly complex scenarios is
                                            trivial for us.
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        ref={ref6}
                                        className={`screenshot animated2 animatedFadeInUp ${
                                            isVisible6 ? 'fadeInUp' : ''
                                        }`}
                                    >
                                        <img
                                            src={bell}
                                            alt="ZEUS White Monitoring and alerts"
                                        />
                                        <h3>
                                            <p className="highlighted-text">
                                                Monitoring and alerts
                                            </p>
                                        </h3>
                                        <p className="subtitle">
                                            Be alerted as soon as any part of
                                            your infrastructure goes down so you
                                            can strive for 100% uptime.
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        ref={ref7}
                                        className={`screenshot animated4 animatedFadeInUp ${
                                            isVisible7 ? 'fadeInUp' : ''
                                        }`}
                                    >
                                        <img
                                            src={phone}
                                            alt="ZEUS White On-call support"
                                        />
                                        <h3>
                                            <p className="highlighted-text">
                                                On-call support
                                            </p>
                                        </h3>
                                        <p className="subtitle">
                                            Sometimes you just need a friendly
                                            and knowledgeable voice on the line.
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div
                                        ref={ref8}
                                        className={`screenshot animated4 animatedFadeInUp ${
                                            isVisible8 ? 'fadeInUp' : ''
                                        }`}
                                    >
                                        <img
                                            src={enterprise}
                                            alt="ZEUS White Enterprise integrations"
                                        />
                                        <h3>
                                            <p className="highlighted-text">
                                                Enterprise integrations
                                            </p>
                                        </h3>
                                        <p className="subtitle">
                                            Integrate Bitcoin and Lightning
                                            payments into your business.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="contactForm">
                            <h4>Contact us</h4>
                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <p>
                                    Tell us about your business needs and let's
                                    see if we can help you thrive with Bitcoin.
                                </p>
                            </div>
                            {!isSubmitting && successMessage && (
                                <p style={{ textAlign: 'center' }}>
                                    {successMessage}
                                </p>
                            )}
                            {!isSubmitting && errorMessage && (
                                <p
                                    style={{
                                        textAlign: 'center',
                                        color: 'darkred'
                                    }}
                                >
                                    {errorMessage}
                                </p>
                            )}
                            {isSubmitting && !successMessage && !errorMessage && (
                                <div style={{ marginBottom: 50 }}>
                                    <LoadingIndicator />
                                </div>
                            )}
                            {!isSubmitting && !successMessage && !errorMessage && (
                                <div className="form-container">
                                    <form onSubmit={sendEmail}>
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={name}
                                            onChange={(event) =>
                                                setName(event.target.value)
                                            }
                                        />
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={(event) =>
                                                setEmail(event.target.value)
                                            }
                                        />
                                        <label>Message</label>
                                        <textarea
                                            name="message"
                                            value={message}
                                            onChange={(event) =>
                                                setMessage(event.target.value)
                                            }
                                        />
                                        <input
                                            type="submit"
                                            value="SEND"
                                            disabled={
                                                !name || !email || !message
                                            }
                                        />
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
