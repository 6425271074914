import { useEffect, useMemo, useState } from 'react';

function useOnScreen(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                // Only update state if we haven't already set it to true
                if (!isIntersecting && entry.isIntersecting) {
                    setIntersecting(true);
                }
            }),
        [ref]
    );

    useEffect(() => {
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    return isIntersecting;
}

export { useOnScreen };
