import { useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import WebFont from 'webfontloader';

import { useOnScreen } from './Utils';

import Header from './Header';
import Footer from './Footer';

import appleAppStore from './images/apple-app-store.png';
import googlePlay from './images/google-play.png';
import androidDownload from './images/android-download.png';

import app1 from './images/app1.png';
import app2 from './images/app2.png';
import app3 from './images/app3.png';

import integrations from './images/zeus-integrations.png';

import bust from './images/bust.png';

import map from './images/map.png';
import hyper from './images/hyper.jpg';

import config from './config.json';

const androidUrl = `/zeus-${config.latestVersion}-universal.apk`;
const releaseNotesUrl = `https://github.com/ZeusLN/zeus/releases/tag/${config.latestVersion}`;

const androidUrlBeta = `/zeus-${config.latestBetaVersion}-universal.apk`;
const releaseNotesUrlBeta = `https://github.com/ZeusLN/zeus/releases/tag/${config.latestBetaVersion}`;

const AppStores = ({ signatures = false }: { signatures?: boolean }) => (
    <>
        <a
            href="https://apps.apple.com/us/app/zeus-ln/id1456038895"
            target="_blank"
            rel="noreferrer"
        >
            <img
                src={appleAppStore}
                width="240"
                className="d-inline-block align-top"
                alt="Apple App Store"
            />
        </a>
        <a
            href="https://play.google.com/store/apps/details?id=app.zeusln.zeus"
            target="_blank"
            rel="noreferrer"
        >
            <img
                src={googlePlay}
                width="240"
                className="d-inline-block align-top"
                alt="Google Play"
            />
        </a>
        <a href={androidUrl} target="_blank" rel="noreferrer">
            <img
                src={androidDownload}
                width="240"
                className="d-inline-block align-top"
                alt="Android Download"
            />
        </a>
        {signatures && (
            <>
                <p className="downloadDetails">
                    Latest release: {config.latestVersion}
                </p>
                <p className="downloadDetails">
                    <a href={releaseNotesUrl}>Release notes</a> |{' '}
                    <a href="/PGP.txt">PGP key</a>
                </p>
                {config.latestBetaVersion && (
                    <>
                        <p className="downloadDetails">
                            Latest beta: {config.latestBetaVersion}
                        </p>
                        <p className="downloadDetails">
                            <a href={androidUrlBeta}>APK</a> |{' '}
                            <a href={releaseNotesUrlBeta}>Release notes</a> |{' '}
                            <a href="https://testflight.apple.com/join/vVnODWoi">
                                iOS TestFlight
                            </a>
                        </p>
                    </>
                )}
            </>
        )}
    </>
);

const loadFonts = () => {
    WebFont.load({
        google: {
            families: ['Marlide Display:700', 'PP Neue Montreal:400,700']
        }
    });
};

function Home() {
    const ref1 = useRef<HTMLDivElement>(null);
    const isVisible1 = useOnScreen(ref1);

    const ref2 = useRef<HTMLDivElement>(null);
    const isVisible2 = useOnScreen(ref2);

    const ref3 = useRef<HTMLDivElement>(null);
    const isVisible3 = useOnScreen(ref3);

    const ref4 = useRef<HTMLDivElement>(null);
    const isVisible4 = useOnScreen(ref4);

    useEffect(() => {
        loadFonts();
    }, []);
    return (
        <div className="App">
            <Header />
            <div className="Home">
                <div className="Intro">
                    <Grid container spacing={1} columns={16}>
                        <Grid item md={8}>
                            <div>
                                <div className="Home-s1">
                                    <h2>
                                        <p className="highlighted-text">
                                            Bitcoin payments{' '}
                                            <span>your way</span>
                                        </p>
                                    </h2>
                                    <p className="App-subheader">
                                        ZEUS is a self-custodial, open-source
                                        Bitcoin wallet that puts you in control.
                                    </p>
                                    <div className="appStores">
                                        <AppStores signatures />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={8}>
                            <img
                                src={hyper}
                                width="100%"
                                className="hyper"
                                alt="Zeus connects remotely"
                            />
                        </Grid>
                    </Grid>
                </div>

                <div className="Home-section1">
                    <div className="appScreenshots">
                        <Grid container spacing={1} columns={12}>
                            <Grid item md={4}>
                                <div
                                    ref={ref1}
                                    className={`screenshot animated animatedFadeInUp ${
                                        isVisible1 ? 'fadeInUp' : ''
                                    }`}
                                >
                                    <img src={app1} alt="ZEUS screenshot #1" />
                                    <h3>
                                        <p className="highlighted-text">
                                            Payments you <span>control</span>
                                        </p>
                                    </h3>
                                    <p className="subtitle">
                                        Controlling and managing your Lightning
                                        node channels has never been easier.
                                    </p>
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <div
                                    ref={ref2}
                                    className={`screenshot animated2 animatedFadeInUp ${
                                        isVisible2 ? 'fadeInUp' : ''
                                    }`}
                                >
                                    <img src={app2} alt="ZEUS screenshot #2" />
                                    <h3>
                                        <p className="highlighted-text">
                                            Ultimate <span>flexibility</span>
                                        </p>
                                    </h3>
                                    <p className="subtitle">
                                        Run a fully-featured Lightning node on
                                        your phone, or connect to one remotely.
                                    </p>
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <div
                                    ref={ref3}
                                    className={`screenshot animated3 animatedFadeInUp ${
                                        isVisible3 ? 'fadeInUp' : ''
                                    }`}
                                >
                                    <img src={app3} alt="ZEUS screenshot #3" />
                                    <h3>
                                        <p className="highlighted-text">
                                            Payments made <span>simple</span>
                                        </p>
                                    </h3>
                                    <p className="subtitle">
                                        Easily send or receive bitcoin with a
                                        feature-rich, yet simple to use
                                        interface.
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div
                        ref={ref4}
                        className={`integrations animated animatedFadeInUp ${
                            isVisible4 ? 'fadeInUp' : ''
                        }`}
                    >
                        <Grid container spacing={3} columns={12}>
                            <Grid item md={6}>
                                <div className="right">
                                    <h3>
                                        <p className="highlighted-text">
                                            Connect from <span>anywhere</span>
                                        </p>
                                    </h3>
                                    <p className="subtitle">
                                        Integrations on all your favorite
                                        platforms make it easy to enjoy ZEUS'
                                        first-in-class remote control
                                        experience.
                                    </p>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <img
                                    src={integrations}
                                    width="490"
                                    className="d-inline-block align-top"
                                    alt="ZEUS integrations"
                                />
                            </Grid>
                        </Grid>
                    </div>
                    {/* <div className="features">
                        <Grid container spacing={1} columns={12}>
                            <Grid item md={4}>
                                <div className="right">
                                    <h3>
                                        <p className="highlighted-text">
                                            Connect from <span>anywhere</span>
                                        </p>
                                    </h3>
                                    <p>
                                        Integrations on all your favorite
                                        platforms make it easy to enjoy ZEUS'
                                        first-in-class remote control
                                        experience.
                                    </p>
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <div className="right">
                                    <h3>
                                        <p className="highlighted-text">
                                            Connect from <span>anywhere</span>
                                        </p>
                                    </h3>
                                    <p>
                                        Integrations on all your favorite
                                        platforms make it easy to enjoy ZEUS'
                                        first-in-class remote control
                                        experience.
                                    </p>
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <div className="right">
                                    <h3>
                                        <p className="highlighted-text">
                                            Connect from <span>anywhere</span>
                                        </p>
                                    </h3>
                                    <p>
                                        Integrations on all your favorite
                                        platforms make it easy to enjoy ZEUS'
                                        first-in-class remote control
                                        experience.
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </div> */}
                </div>
                <div className="Home-section4">
                    <img
                        src={bust}
                        style={{
                            width: '100%',
                            maxWidth: '620px'
                        }}
                        className="d-inline-block align-top"
                        alt="ZEUS bust"
                    />
                </div>
                <div className="Home-section3">
                    <h2>
                        <p className="highlighted-text">
                            <span>Lightning</span> in the palm of your hand
                        </p>
                    </h2>
                    <div className="appStores2">
                        <AppStores />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
